import { CloseAdminPanelButton } from './CloseAdminPanelButton';

type AdminPanelHeaderProps = {
  onClose: () => void;
  title: string;
  hideCloseButton?: boolean;
}

export const AdminPanelHeader = ({onClose, title, hideCloseButton}: AdminPanelHeaderProps) => (
  <div className='admin-panel__form-header'>
    <div className='workspace-header__title workspace-header__block'>{title}</div>
    {hideCloseButton? null:
    <CloseAdminPanelButton onClick={onClose} />}
  </div>
)