import React from 'react';

interface IconProps {
  fill?: string;
}

export const ChatIcon: React.FC<IconProps> = ({ fill }) => (
 <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g id="Discussing_criticize">
 <path fill={fill? fill: "#000"} d="M37.9888,26.2265H5.4407A2.7237,2.7237,0,0,0,2.717,28.95V50.4772a2.7238,2.7238,0,0,0,2.7237,2.7237H15.52a1.4559,1.4559,0,0,1,1.4558,1.4558v5.081a1.6046,1.6046,0,0,0,2.6755,1.195l8-7.17a2.2017,2.2017,0,0,1,1.4689-.562h8.8685a2.7238,2.7238,0,0,0,2.7236-2.7237V28.95A2.7237,2.7237,0,0,0,37.9888,26.2265ZM21.7146,44.2543H11.0076a1.35,1.35,0,1,1,0-2.6992h10.707a1.35,1.35,0,1,1,0,2.6992Zm10.708,0H28.2468a1.35,1.35,0,1,1,0-2.6992h4.1758a1.35,1.35,0,0,1,0,2.6992Zm0-6.8379H11.0076a1.35,1.35,0,1,1,0-2.6992h21.415a1.35,1.35,0,0,1,0,2.6992Z"/>
 <path fill={fill? fill: "#000"} d="M58.5593,6.6876H26.0112a2.7236,2.7236,0,0,0-2.7236,2.7237V23.5266H37.9888A5.43,5.43,0,0,1,43.4123,28.95v7.16l5.8951,5.2834a1.6047,1.6047,0,0,0,2.6757-1.1948v-5.081a1.4556,1.4556,0,0,1,1.4556-1.4558h5.1206a2.7237,2.7237,0,0,0,2.7236-2.7238V9.4113A2.7236,2.7236,0,0,0,58.5593,6.6876ZM52.9929,24.59h-7.11a1.35,1.35,0,1,1,0-2.6992h7.11a1.35,1.35,0,1,1,0,2.6992Zm0-6.6319H31.5779a1.35,1.35,0,1,1,0-2.6992h21.415a1.35,1.35,0,1,1,0,2.6992Z"/></g></svg>
);