import { useEffect, useState } from 'react';
import { ChannelList,useChatContext } from 'stream-chat-react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { ChannelPreview } from '../ChannelPreview/ChannelPreview';
import SyncIcon from '@mui/icons-material/Sync';
import { ChatIcon } from './icons';
import ExploreIcon from '@mui/icons-material/Explore';
import type { Channel, ChannelFilters } from 'stream-chat';
import { ChannelSort } from 'stream-chat';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StreamChatType } from '../../types';
import { Workspace, useWorkspaceController } from '../../context/WorkspaceController';

const options = { state: true, watch: true, presence: true};

const sort: ChannelSort<StreamChatType> = { last_message_at: -1, updated_at: -1 };

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


interface MenuSelectionBarProps {
  selected: Workspace;
  hasGps: boolean;
}

const defaultWorkspace: Workspace = "Chat";

const MenuSelectionBar: React.FC<MenuSelectionBarProps> = ({ selected, hasGps }) => {
  const { displayWorkspace } = useWorkspaceController();
  return (
    <div className='sidebar__company-selection-bar'>
      <div className={`sidebar__company-badge ${selected.match("Chat")? "selected": ""}`} onClick={() => displayWorkspace('Chat' as Workspace)}>
          <ChatIcon fill={"#3971be"} />
          <p>チャット</p>
      </div>

      {hasGps?
      <div className={`sidebar__company-badge ${selected.match("Map")? "selected": ""}`} onClick={() => displayWorkspace('Map' as Workspace)}>
        <ExploreIcon style={{ color: '3971be' }} />
        <p>地図</p>
      </div>:null
      }

    </div>
  );
}

const customChannelTeamFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'group');
};
const customChannelTeamFilterDirect = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === 'team' && (channel.data as any)?.member_count === 2);
};

interface UserData {
  id: string;
  [key: string]: any;
}

interface Group {
  type: string;
  [key: string]: any;
}

interface SidebarProps {
  userData: UserData;
  groups: Array<Group>;
  parentGroupName: string;
}


export const Sidebar: React.FC<SidebarProps> = ({ userData, groups, parentGroupName}) => {
  const { client } = useChatContext<StreamChatType>();
  const [filters, setFilters] = useState([{},{}]);
  const [isChatClientReady, setIsChatClientReady] = useState(false);
  const { activeWorkspace, changeDate, updateRealtimeData } = useWorkspaceController();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    //temp until we store the state
    window.location.reload();
  }

  const has_gps_plan = userData?.customData?.platform_access ? userData?.customData?.platform_access.includes("GpsPlan"): false;

  useEffect(() => {
    if (client?.user?.id) {
      setFilters(
        [
        {
          type: 'group',
          members: {
            $in: [client.user.id],
          }
        },
        {
          type: 'team',
          members: {
            $in: [client.user.id],
          },
          member_count: 2
        }]
      );
      setIsChatClientReady(true);
    } else {
      setIsChatClientReady(false);
    }

  }, [client?.user, activeWorkspace]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
    <div className='sidebar'>
      <MenuSelectionBar selected={activeWorkspace? activeWorkspace: defaultWorkspace} hasGps={has_gps_plan} />
      <div className='channel-list-bar'>
        <div className='channel-list-bar__header'>
          <div className='channel-list-user-info'>
            {client?.user ?
              <>
                {client?.user?.image?
                  <div className="custom-avatar" style={{backgroundImage: `url(${client?.user?.image})`}}/>:<div className="no-avatar"></div>}
                  <div className="user-info-stack">
                  <h4 className="channel-list-user-name">{client?.user?.name? client.user.name: client.user.id}</h4>
                  {client?.user?.company_id?
                    <span className="user-company-info">{parentGroupName && parentGroupName !== ""? parentGroupName: client.user.company_id}</span>:null}
                </div>

              </>:
              <></>
            }
            <div className="more-button-container">
              <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
              <MoreVertIcon style={{color: "#fff"}}/>
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={logoutUser}>ログアウト</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        
        {activeWorkspace.match("Map")?
        <div className="date-time-picker-sidebar">
          <div className="picker-container">
            <StaticDatePicker onChange={changeDate} displayStaticWrapperAs="desktop" className="date-picker-static"/>
          </div>
         <Button variant="outlined" onClick={updateRealtimeData} style={{backgroundColor: "#ffcc33", color: "#7d650e", width: "100%", marginTop: -30}} startIcon={<SyncIcon />}>
         更新
        </Button>

        </div>:
        <>
        {isChatClientReady?
        <>
        <ChannelSearch />
        <div className='channel-list-bar__content-container'>

         <ChannelList
            channelRenderFilterFn={customChannelTeamFilter}
            filters={filters[0]}
            options={options}
            sort={sort}
            List={(listProps) => (
              <TeamChannelList
                {...listProps}
                type='team'
              />
            )}
            Preview={(previewProps) => (
              <ChannelPreview
                {...previewProps}
                type='team'
              />
            )}
          />

        <ChannelList
          channelRenderFilterFn={customChannelTeamFilterDirect}
          filters={filters[1]}
          options={options}
          sort={sort}
          setActiveChannelOnMount={false}
          List={(listProps) => (
            <TeamChannelList
              {...listProps}
              type='messaging'
            />
          )}
          Preview={(previewProps) => (
            <ChannelPreview
              {...previewProps}
              type='messaging'
            />
          )}
        />
        </div>
        </>:<div style={{color: "#fff", fontSize: 12}}>チャネル読み込み中</div>}
        </>
        
        }
      </div>
    </div>
    </ThemeProvider>
  );
};
