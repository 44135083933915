import { useEffect, useMemo, useState } from 'react';

import { AdminPanelHeader } from './AdminPanelHeader';
import { AdminPanelFooter } from './AdminPanelFooter';
import { ChannelNameInputField } from './ChannelNameInputField';
import { UserList } from './UserList';
import { Avatar, useChatContext } from 'stream-chat-react';
import type { StreamChatType } from '../../types';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';


export const EditChannel = () => {
  const { closeAdminPanel } = useWorkspaceController();
  const {name, members, handleInputChange, handleSubmit, leaveChannel, errors} = useAdminPanelFormState();
  const { client, channel } = useChatContext<StreamChatType>();
  const channelMembers = useMemo(() => channel?.state.members
      ? Object.keys(channel.state.members)
      : [],
    [channel?.state?.members],
  );
  return (
    <div className='admin-panel__form'>
      <AdminPanelHeader onClose={closeAdminPanel} title={channelMembers && channelMembers.length > 2? 'グループを編集': `${name}のダイレクトメッセージ設定`}/>

      {channelMembers && channelMembers.length > 2?
      <>
      <ChannelNameInputField name={name} error={errors.name} onChange={handleInputChange} />
      <UserList emptyList={false} directMessage={false}/>
      </>:<div style={{display: 'flex', flex: 1}}/>}

      <AdminPanelFooter buttonText='保存' actionType="update" needsSaveButton={channelMembers && channelMembers.length > 2? true: false} onLeaveClick={leaveChannel} onButtonClick={handleSubmit}/>
    </div>
  );
};
