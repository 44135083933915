import clsx from 'clsx';
import { useMemo, useRef } from 'react';
import {
  AttachmentPreviewList,
  ChatAutoComplete,
  EmojiPicker,
  SendButton,
  useChannelStateContext, useComponentContext,
  useMessageInputContext,
} from 'stream-chat-react';
import { useDropzone } from 'react-dropzone';

import { GiphyBadge } from './GiphyBadge';
import { MessageInputControlButton } from './MessageInputControls';
import { useGiphyInMessageContext } from '../../context/GiphyInMessageFlagContext';
import { useMessageInputCompositionControls } from './hooks/useMessageInputCompositionControls';

import type { StreamChatType } from '../../types';

export const TeamMessageInput = () => {
  const {TypingIndicator} = useComponentContext();

  const acceptedFiles = [
    'application/zip',
    'application/vnd.rar',
    'application/pdf',
    'application/x-7z-compressed',
    'image/vnd.adobe.photoshop',
    'application/vnd.ms-excel',
    'application/msword',
    'application/gzip',
    'application/vnd.android.package-archive',
    'image/gif',
    'application/vnd.ms-powerpoint',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'audio/mpeg',
    'audio/wav',
    'audio/x-wav',
    'audio/ogg',
    'audio/webm',
    'audio/aac',
    'audio/flac',
    'audio/opus',
    'audio/mp4',
    'audio/x-m4a'
  ];

  const {
    multipleUploads,
  } = useChannelStateContext<StreamChatType>();

  const {
    handleSubmit,
    numberOfUploads,
    text,
    uploadNewFiles,
    maxFilesLeft,
    isUploadEnabled,
    openEmojiPicker,
    closeEmojiPicker,
    emojiPickerIsOpen,
  } = useMessageInputContext<StreamChatType>();
  const { isComposingGiphyMessage } = useGiphyInMessageContext();
  const {
    formatting,
    handleBoldButtonClick,
    handleCodeButtonClick,
    handleItalicsButtonClick,
    handleStrikeThroughButtonClick,
    onChange,
    placeholder,
  } = useMessageInputCompositionControls();

  const accept = useMemo(() => acceptedFiles.join(','), [acceptedFiles]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: acceptedFiles as any,
    disabled: false,
    multiple: multipleUploads,
    noClick: true,
    onDrop: uploadNewFiles,
  });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFilePickerButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div {...getRootProps({ className: clsx(`team-message-input__wrapper`) })}>
      <input {...getInputProps()} ref={fileInputRef} />
      {isDragActive && (
        <div
          className={clsx('str-chat__dropzone-container', {
            'str-chat__dropzone-container--not-accepted': isDragReject,
          })}
        >
          {!isDragReject && <p>ファイルをここにドラッグしてください</p>}
          {isDragReject && <p>一部のファイルは受け付けられません</p>}
        </div>
      )}
      <div className='team-message-input__input'>
        <div className='team-message-input__top'>
          {!!numberOfUploads && <AttachmentPreviewList />}
          <div className='team-message-input__form'>
            {isComposingGiphyMessage() && !numberOfUploads && <GiphyBadge />}
            <ChatAutoComplete onChange={onChange} placeholder={placeholder} />
            <SendButton
              disabled={!numberOfUploads && !text.length}
              sendMessage={handleSubmit}
            />
          </div>
        </div>
        <div className='team-message-input__bottom'>
            <MessageInputControlButton type='attach' onClick={handleFilePickerButtonClick} />
            <MessageInputControlButton type='emoji' onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker} />
            <MessageInputControlButton type='bold' active={formatting === 'bold'} onClick={handleBoldButtonClick} />
            <MessageInputControlButton type='italics' active={formatting === 'italics'} onClick={handleItalicsButtonClick} />
            <MessageInputControlButton type='strike-through' active={formatting === 'strike-through'} onClick={handleStrikeThroughButtonClick} />
            <MessageInputControlButton type='code' active={formatting === 'code'} onClick={handleCodeButtonClick} />
        </div>
      </div>
      {TypingIndicator && <TypingIndicator />}
      <EmojiPicker />
    </div>
  );
};
