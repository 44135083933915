import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
dayjs.extend(utc); 
const colors = [
  '#FF007F', // Rose
  '#7F00FF', // Violet
  '#00FF7F', // Spring Green
  '#007FFF', // Azure
  '#7FFF00', // Chartreuse
  '#FF00FF', // Magenta
  '#0000FF', // Blue
  '#00FFFF', // Cyan
  '#00FF00', // Green
  '#FFFF00', // Yellow
  '#FF0000', // Red
];

const hoverColors = [
  '#CC0059', // Darker Rose
  '#5900CC', // Darker Violet
  '#00CC59', // Darker Spring Green
  '#0059CC', // Darker Azure
  '#59CC00', // Darker Chartreuse
  '#FF33FF', // Brighter Magenta
  '#3333FF', // Brighter Blue
  '#33FFFF', // Brighter Cyan
  '#33FF33', // Brighter Green
  '#CCCC00', // Darker Yellow
  '#FF3333', // Brighter Red
];


const mapPinStart = (colorIndex, markerHovered) => {
    return ({
              url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(`

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 18.9 18.9" style="enable-background:new 0 0 18.9 18.9;" xml:space="preserve">
<circle cx="9.5" cy="9.5" r="9.5" style="fill:${markerHovered? hoverColors[colorIndex % hoverColors.length]: colors[colorIndex % colors.length]};"/>
<g>
  <circle style="fill:#FFFFFF;" cx="9.5" cy="6" r="2.6"/>
  <path style="fill:#FFFFFF;" d="M9.5,10.2c-1.7,0-5.1,0.9-5.1,2.6c1.1,1.7,3,2.8,5.1,2.8c2.1,0,4-1.1,5.1-2.8
    C14.6,11.2,11.2,10.2,9.5,10.2z"/>
</g>
</svg>
`),
    scaledSize: new window.google.maps.Size(18, 18),
    anchor: new window.google.maps.Point(3, 5)
  })
}

const mapPinEnd = (colorIndex, markerHovered) => {
    return ({
          url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(`
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 28 28" style="enable-background:new 0 0 28 28;" xml:space="preserve">
<g>
  <circle style="fill:${markerHovered? hoverColors[colorIndex % hoverColors.length]: colors[colorIndex % colors.length]};" cx="14.1" cy="14.1" r="10"/>
  <path style="fill:${markerHovered? hoverColors[colorIndex % hoverColors.length]: colors[colorIndex % colors.length]};" d="M14,0C6.3,0,0,6.3,0,14c0,7.7,6.3,14,14,14s14-6.3,14-14C28,6.3,21.7,0,14,0z M14,25.6C7.6,25.6,2.4,20.4,2.4,14
    C2.4,7.6,7.6,2.4,14,2.4c6.4,0,11.6,5.2,11.6,11.6C25.5,20.4,20.4,25.6,14,25.6z"/>
</g>
<g>
  <circle style="fill:#FFFFFF;" cx="14.1" cy="10.4" r="2.7"/>
  <path style="fill:#FFFFFF;" d="M14.1,14.9c-1.8,0-5.4,1-5.4,2.8c1.2,1.8,3.2,2.9,5.4,2.9c2.2,0,4.3-1.2,5.4-2.9
    C19.5,15.9,15.9,14.9,14.1,14.9z"/>
</g>
</svg>
`),
    scaledSize: new window.google.maps.Size(28, 28)
  })
  }


export const MapMarker = ({ location, username, userId, colorIndex, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [markerHovered, setMarkerHovered] = useState(false);

  const latLng = { lat: location.latitude, lng: location.longitude };
  
  const icon = type === "start" ? mapPinStart(colorIndex, markerHovered) : mapPinEnd(colorIndex, markerHovered);
  

  return (
    <Marker
      key={`${location.latitude}-${location.longitude}`}
      position={latLng}
      options={{optimized: false, zIndex: markerHovered? 9: 5}}
      icon={icon}
      onClick={() => setIsOpen(true)}
      onMouseOver={() =>setMarkerHovered(true)}
      onMouseOut={() => setMarkerHovered(false)}
    >
      {isOpen && (
        <InfoWindow position={latLng} options={{
          zIndex: markerHovered? 9: 5,
          }}
          onCloseClick={() => setIsOpen(false)}
        >
          <div>
            <p style={{color: `${type === "start"? "#50b630": "#ff0064"}`, margin: 0, fontSize: "9px", fontWeight: "bold"}}>
              {type === "start"? "GPS追跡開始": "GPS追跡終了"}
            </p>

            <p style={{color: `${markerHovered? "#2257b6": "#000"}`, margin: 0}}>{username}</p>

            {username !== userId?
              <p style={{color: "#7c7c7c", margin: 0, fontSize: "7px"}}>{userId}</p>
            :null}

            <p style={{color: "#000", margin: 0}}>
              {dayjs(location.timestamp).utc().format("YYYY/MM/DD @ HH:mm")}
            </p>

            <p style={{color: "#000", margin: 0, fontSize: 9}}>精度{location.accuracy}m</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}