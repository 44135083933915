import { MouseEventHandler } from 'react';

type AdminPanelFooterProps = {
  buttonText: string;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
  onLeaveClick?: MouseEventHandler<HTMLButtonElement>;
  actionType?: string;
  needsSaveButton?: boolean;
}

export const AdminPanelFooter = ({buttonText, onButtonClick, onLeaveClick, actionType, needsSaveButton}: AdminPanelFooterProps) => (
  <div className='admin-panel__form-footer' >
    {actionType && actionType === "update"?
      <button style={{backgroundColor: "#ff1a68"}} onClick={onLeaveClick}>退出</button>:null}
    {needsSaveButton? 
      <button onClick={onButtonClick}>{buttonText}</button>:null}
  </div>
)