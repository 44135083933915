import {
  ChangeEventHandler,
  createContext,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Workspace, useWorkspaceController } from '../../../context/WorkspaceController';
import { useChatContext, generateRandomId } from 'stream-chat-react';
import { StreamChatType } from '../../../types';

type UpsertChannelParams = { name: string, members: string[] };

type ChannelType = 'team' | 'messaging';

type UpsertAction = 'create' | 'update';

export type FormValues = {
  name: string;
  members: string[];
};

export type FormErrors = {
  name: string | null;
  members: string | null;
};

type HandleMemberSelectHandler = (event: React.ChangeEvent<HTMLInputElement>, directMessage: boolean) => void;

type AdminPanelFormContext = FormValues & {
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleMemberSelect: HandleMemberSelectHandler;
  handleSubmit: MouseEventHandler<HTMLButtonElement>;
  leaveChannel: MouseEventHandler<HTMLButtonElement>;
  createChannelType?: ChannelType;
  errors: FormErrors;
};


const Context = createContext<AdminPanelFormContext>({
  handleInputChange: () => null,
  handleMemberSelect: () => null,
  handleSubmit: () => null,
  members: [],
  name: '',
  leaveChannel: () => null,
  errors: { name: null, members: null },
});


type AdminPanelFormProps = {
  workspace: Workspace;
  onSubmit: () => void;
  defaultValues: FormValues;
}

const getChannelTypeFromWorkspaceName = (workspace: Workspace): ChannelType | undefined => (
  workspace.match(/.*__(team|messaging)/)?.[1] as ChannelType | undefined
);

const getUpsertAction = (workspace: Workspace): UpsertAction | undefined => {
  if (workspace.match('Channel-Create')) return 'create';
  if (workspace.match('Channel-Edit')) return 'update';
};

export const AdminPanelForm = ({ children, defaultValues, workspace, onSubmit }: PropsWithChildren<AdminPanelFormProps>) => {
  const { client, channel, setActiveChannel } = useChatContext<StreamChatType>();
  const [name, setChannelName] = useState<string>(defaultValues.name);
  const [members, setMembers] = useState<string[]>(defaultValues.members);
  const [errors, setErrors] = useState<FormErrors>({ name: null, members: null });

  const { displayWorkspace } = useWorkspaceController();

  const createChannelType = getChannelTypeFromWorkspaceName(workspace);
  const action = getUpsertAction(workspace);

  const createChannel = useCallback(async ({ name, members }: UpsertChannelParams) => {
    if (!createChannelType || members.length === 0) return;
    if(!client) return;
    if(!client?.user?.teams?.[0]) return;

    try {
      let newChannel;

      if(members.length > 2) {
        //create a unique group channel (can add and remove users)
        if(!name) return;

        newChannel = await client.channel("group", generateRandomId(), {
          name,
          members,
          team: client?.user?.teams?.[0],
        });
       
      } else {
        //create only one channel for the members, no duplicates created (can't add or remove members)
        // https://getstream.io/chat/docs/react/creating_channels/#2.-creating-a-channel-for-a-list-of-members
        newChannel = await client.channel("team", {
          members,
          team: client?.user?.teams?.[0],
        });
      }

      await newChannel.watch();
      setActiveChannel(newChannel)
    } catch(e) {
      alert(e);
    }
  }, [createChannelType, setActiveChannel, client]);

  const updateChannel = useCallback(async ({ name, members }: UpsertChannelParams) => {
    if (name !== (channel?.data?.name || channel?.data?.id)) {
      await channel?.update(
        { ...channel.data, name: name},
        { text: `グループ名を「${name}」に変更` },
      );
    }

    if (members?.length) {
      await channel?.addMembers(members);
    }
  }, [channel]);

  const leaveChannel: MouseEventHandler<HTMLButtonElement> = useCallback(async (event) => {
    if (client?.user?.id) {
      await channel?.removeMembers([client.user.id]);
      setChannelName("");
      displayWorkspace(`Admin-Admin-Channel-Create__team` as Workspace);
    }
  }, [channel]);

  const validateForm = useCallback(({action, createChannelType, values}:{values: FormValues, createChannelType?: ChannelType, action?: UpsertAction}): FormErrors | null => {
    let errors:FormErrors = { name: null, members: null };
    console.log("check for errors");
    if (action === 'create') {
      errors = {
        name: !values.name && createChannelType === 'team' ? 'タイトルは必須です' : null,
        members: values.members.length < 3  && createChannelType === 'team'  ? '最低2名の追加メンバーが必要です' : null,
      };
    }

    if (action === 'update' && values.name === defaultValues.name && values.members.length === 0) {
      errors = {
        name: '名称は変更しない（名称変更またはメンバー追加）',
        members: '新規メンバー追加なし（名称変更またはメンバー追加）',
      };
    }

    return Object.values(errors).some(v => !!v) ?  errors : null;
  }, [defaultValues.name]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(async (event) => {
    event.preventDefault();
    const errors = validateForm({values: {name, members}, action, createChannelType});

    if (errors) {
      setErrors(errors);
      return;
    }

    try {
      if (action === 'create') await createChannel({ name, members});
      if (action === 'update') await updateChannel({ name, members});
      onSubmit();
    } catch (err) {
      console.error(err);
    }
  }, [action, createChannelType, name, members, createChannel, updateChannel, onSubmit, validateForm]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    event.preventDefault();
    setChannelName(event.target.value);
  }, []);


    const handleMemberSelect: HandleMemberSelectHandler = useCallback((event, directMessage) => {
      setMembers((prevMembers) => {
        const { value } = event.target;
    
        if (directMessage) {
          // For direct messages, we want to toggle the member at index 1
          if (prevMembers && prevMembers.length > 1) {
            // If there are two members, compare the new value with the second member
            if (value === prevMembers[1]) {
              // If the new value is the same as the second member, remove the second member
              return [prevMembers[0]];
            } else {
              // Otherwise, update the second member
              return [prevMembers[0], value];
            }
          } else {
            // If there are not yet two members, add the new value as the second member
            return [prevMembers[0], value];
          }
        } else {
          // For non-direct messages, proceed as normal
          if (event.target.checked) {
            return prevMembers.length ? [...prevMembers, value] : [value];
          }
          return prevMembers?.filter((prevUser) => prevUser !== value);
        }
      });
    }, []);
  
  

  useEffect(() => {

    setMembers(defaultValues.members)
    if(!createChannelType) {
      if(channel && !channel.data?.name) {
        const channelMembers = Object.values(channel.state.members);
        const channelMembersFiltered = channelMembers.filter((u) => u.user_id !== client?.user?.id);
        const memberNames = channelMembersFiltered.map(obj => obj?.user?.name || obj?.user_id);
        setChannelName(memberNames.join(", "));
      } else {
        setChannelName(defaultValues.name);
      }
    }
  }, [defaultValues, createChannelType, channel]);

  return (
    <Context.Provider value={{
      createChannelType,
      errors,
      name,
      members,
      handleInputChange,
      handleMemberSelect,
      handleSubmit,
      leaveChannel
    }}>
      {children}
    </Context.Provider>
  );
};

export const useAdminPanelFormState = () => useContext(Context);