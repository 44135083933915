import React, { useEffect,useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, Polyline  } from '@react-google-maps/api';
import { useWorkspaceController } from '../../context/WorkspaceController';
import { MapMarker } from './MapMarker.js';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import axios from 'axios';

const MAPS_API_KEY = 'AIzaSyD7WC_mBt8r9GXRw7-ZYYGWweMB02D9y5w';
const containerStyle = {
  width: '100%',
  height: '100%'
};

const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      { visibility: "off" }
    ]
  }
];

type MarkerData = {
  position: {
    lat: number,
    lng: number
  },
  icon: {
    url: string,
    scaledSize: google.maps.Size
  }
}

type LocationData = {
  latitude: number;
  longitude: number;
  timestamp: string;
  userId: string;
  accuracy: number;
  altitude: number;
  altitude_accuracy: number;
  is_moving: boolean;
  speed: number;
  speed_accuracy: number;
};

const axi = axios.create({
  baseURL: 'https://kxftn9t3e3.execute-api.ap-northeast-1.amazonaws.com/default/',
  headers: {
    "x-api-key": "4GxJHNsnTr5htrz1RqDwu9PEdkQJYLjn5xYvqR6U",
    'Content-Type': 'application/json'
  }
});

const colors = [
  '#FF007F', // Rose
  '#7F00FF', // Violet
  '#00FF7F', // Spring Green
  '#007FFF', // Azure
  '#7FFF00', // Chartreuse
  '#FF00FF', // Magenta
  '#0000FF', // Blue
  '#00FFFF', // Cyan
  '#00FF00', // Green
  '#FFFF00', // Yellow
  '#FF0000', // Red
];

export const MapView = ({ companyUserIds, geocore }: { companyUserIds: string[]; geocore: any }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAPS_API_KEY
  })

  const [map, setMap] = useState<google.maps.Map | null>(null)

  const { dateSelectedFilter, realtimeRefreshInt } = useWorkspaceController();

  const [elements, setElements] = useState<JSX.Element[]>([]);

  const [loadingRealtime, setLoadingRealtime] = useState(true);

  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 35.6528,
    lng: 139.8395,
  });
  const [zoom, setZoom] = useState(10);

  const mapPinInaccurate = (colorIndex:number) => {
    return ({
            url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(`        
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 18.9 18.9" style="enable-background:new 0 0 18.9 18.9;opacity:0.2" xml:space="preserve">
<circle cx="9.5" cy="9.5" r="9.5" style="fill:${colors[colorIndex % colors.length]};"/>
<g>
  <circle style="fill:#FFFFFF;" cx="9.5" cy="6" r="2.6"/>
  <path style="fill:#FFFFFF;" d="M9.5,10.2c-1.7,0-5.1,0.9-5.1,2.6c1.1,1.7,3,2.8,5.1,2.8c2.1,0,4-1.1,5.1-2.8
    C14.6,11.2,11.2,10.2,9.5,10.2z"/>
</g>
</svg>`),
            scaledSize: new window.google.maps.Size(18, 18),
            anchor: new window.google.maps.Point(3, 9)
    })
  }

  const mapPinStart = (colorIndex:number) => {
    return ({
          url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(`

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 18.9 18.9" style="enable-background:new 0 0 18.9 18.9;" xml:space="preserve">
<circle cx="9.5" cy="9.5" r="9.5" style="fill:${colors[colorIndex % colors.length]};"/>
<g>
  <circle style="fill:#FFFFFF;" cx="9.5" cy="6" r="2.6"/>
  <path style="fill:#FFFFFF;" d="M9.5,10.2c-1.7,0-5.1,0.9-5.1,2.6c1.1,1.7,3,2.8,5.1,2.8c2.1,0,4-1.1,5.1-2.8
    C14.6,11.2,11.2,10.2,9.5,10.2z"/>
</g>
</svg>
`),
          scaledSize: new window.google.maps.Size(18, 18),
          anchor: new window.google.maps.Point(3, 5)
  })
  }

  const mapPinEnd = (colorIndex:number) => {
    return ({
          url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(`
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 28 28" style="enable-background:new 0 0 28 28;" xml:space="preserve">
<g>
  <circle style="fill:${colors[colorIndex % colors.length]};" cx="14.1" cy="14.1" r="10"/>
  <path style="fill:${colors[colorIndex % colors.length]};" d="M14,0C6.3,0,0,6.3,0,14c0,7.7,6.3,14,14,14s14-6.3,14-14C28,6.3,21.7,0,14,0z M14,25.6C7.6,25.6,2.4,20.4,2.4,14
    C2.4,7.6,7.6,2.4,14,2.4c6.4,0,11.6,5.2,11.6,11.6C25.5,20.4,20.4,25.6,14,25.6z"/>
</g>
<g>
  <circle style="fill:#FFFFFF;" cx="14.1" cy="10.4" r="2.7"/>
  <path style="fill:#FFFFFF;" d="M14.1,14.9c-1.8,0-5.4,1-5.4,2.8c1.2,1.8,3.2,2.9,5.4,2.9c2.2,0,4.3-1.2,5.4-2.9
    C19.5,15.9,15.9,14.9,14.1,14.9z"/>
</g>
</svg>
`),
          scaledSize: new window.google.maps.Size(28, 28)
        })
  }

  const fetchRealtimeLocation = async() => {
    try {
      const realtimeLocationData = await axi.post('/getRealtimeLocations', {
        userIds: companyUserIds,
        date: dateSelectedFilter? dateSelectedFilter?.format("YYYY/MM/DD"): dayjs().format("YYYY/MM/DD")
      });
      setLoadingRealtime(true);
      setupMap(realtimeLocationData.data.results);
    } catch(e) {
      //error getting map info alert
      toast.error('デバイスのGPSデータを取得する際にエラーが発生しました。', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setupMap({});
    }
  }


const setupMap = async(data: { [key: string]: LocationData[] }) => {
  setElements([]);
  const bounds = new window.google.maps.LatLngBounds();
  let result: JSX.Element[] = [];
  let colorIndex = 0;

  const plotLocations = (locations: LocationData[], username: string, userId: string, colorIndex: number, paths: google.maps.LatLngLiteral[][]) => {
    locations.forEach((location, index) => {
    // Create the LatLngLiteral.
    const latLng: google.maps.LatLngLiteral = { lat: location.latitude, lng: location.longitude };

    // Get the time difference between this point and the previous one.
    if (index > 0) {
      const prevLocation = locations[index - 1];
      const prevTime = dayjs(prevLocation.timestamp);
      const currentTime = dayjs(location.timestamp);
      const timeDifference = currentTime.diff(prevTime, 'minute');

      // If the time difference is too large, start a new path.
      if (timeDifference > 10) {
        // Insert an end marker for the previous location
        createEndMarker(prevLocation, username, userId,colorIndex, result);

        // Create a start marker for the new segment
        createStartMarker(location, username, userId,colorIndex, result);

        // Start a new path and push the current location
        paths.push([]);
      }
    } else { // this will handle the case for the first location
      // Create a start marker for the first segment
      createStartMarker(location, username, userId,colorIndex, result);

      // Start a new path and push the current location
      paths.push([]);
    }

    // Add the LatLngLiteral to the path.
    paths[paths.length - 1].push(latLng);

    // Create a Marker on last position
    if (index === locations.length - 1) {
      createEndMarker(location, username, userId,colorIndex, result);
    }

    bounds.extend(latLng);
  });

    // Create a Polyline for each path.
    paths.forEach((path) => {
      result.push(
        <Polyline
          path={path}
          options={{
            strokeColor: colors[colorIndex % colors.length],
            strokeOpacity: 1,
            strokeWeight: 2
          }}
        />
      );
    });
  }

  // Loop through each user.
  for (const userId in data) {
    const locations = data[userId];
    let userInfo = await geocore.users.get(userId);
    let username = userInfo.name ? userInfo.name : userId;

    // Split locations into two arrays based on accuracy
    let accurateLocations: LocationData[] = [];
    let inaccurateLocations: LocationData[] = [];

    locations.forEach(location => {
      if(location.latitude && location.longitude) {
        if(location.accuracy > 35) {
          inaccurateLocations.push(location);
        } else {
          accurateLocations.push(location);
        }
      }
    });

    // Sort data points by timestamp.
    accurateLocations.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    // Initialize an array of paths, each path is an array of LatLngLiterals.
    let paths: google.maps.LatLngLiteral[][] = [[]];

    // Loop through each accurate location.
    plotLocations(accurateLocations, username, userId, colorIndex, paths);

    // Plot single markers for inaccurate locations
    inaccurateLocations.forEach(location => {
      createInaccurateMarker(location, username, userId,colorIndex, result);
    });

    ++colorIndex;
  }

  setElements(result);

  if (bounds && map) {
    if(result.length === 0) {
      toast.info('選択した日付フィルタのデバイスGPSデータレコードは見つかりませんでした。', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const boundsCenter = bounds.getCenter();
      setCenter({
        lat: 35.6528,
        lng: 139.8395
      });
      setZoom(10);
    } else {
      map.fitBounds(bounds);
    }
  }


  setLoadingRealtime(false);

  }


  const createInaccurateMarker = (location: LocationData, username: string, userId: string, colorIndex: number, resultArray: JSX.Element[]) => {
    const latLng: google.maps.LatLngLiteral = { lat: location.latitude, lng: location.longitude };
    resultArray.push(
        <Marker
          key={`${location.latitude}-${location.longitude}`}
          position={latLng}
          options={{optimized:false, zIndex: 0}}
          icon={mapPinInaccurate(colorIndex)}
        >  
        </Marker>
      );
  }


  const createStartMarker = (location:LocationData, username: string, userId: string, colorIndex: number, resultArray: JSX.Element[]) => {
    const latLng: google.maps.LatLngLiteral = { lat: location.latitude, lng: location.longitude };
    resultArray.push(
      <MapMarker
        key={`${location.latitude}-${location.longitude}`}
        location={location}
        username={username}
        userId={userId}
        colorIndex={colorIndex}
        type={"start"}
      />
    );
  }

  const createEndMarker = (location:LocationData, username: string, userId: string, colorIndex: number, resultArray: JSX.Element[]) => {
    const latLng: google.maps.LatLngLiteral = { lat: location.latitude, lng: location.longitude };
    resultArray.push(
      <MapMarker
        key={`${location.latitude}-${location.longitude}`}
        location={location}
        username={username}
        userId={userId}
        colorIndex={colorIndex}
        type={"end"}
      />
    );
  }

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null)
  }, [])

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
    fetchRealtimeLocation();
  };

  useEffect(() =>{
    if(isLoaded && map) {
      fetchRealtimeLocation();
    }
  }, [isLoaded, map, dateSelectedFilter,realtimeRefreshInt])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={zoom}
        center={center}
        options={{styles: mapStyles}}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {elements}
      </GoogleMap>
  ) : <></>
};