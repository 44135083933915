import { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Streami18n } from 'stream-chat-react';

import { getRandomImage } from './assets';
import { useChecklist } from './ChecklistTasks';
import { ChannelContainer } from './components/ChannelContainer/ChannelContainer';
import { MapView } from './components/MapView/MapView';
import { Sidebar } from './components/Sidebar/Sidebar';

import { WorkspaceController } from './context/WorkspaceController';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'dayjs/locale/ja';

import type { StreamChatType } from './types';

const urlParams = new URLSearchParams(window.location.search);

const apiKey = urlParams.get('apikey') || process.env.REACT_APP_STREAM_KEY;
const user = urlParams.get('user') || process.env.REACT_APP_USER_ID;
const theme = urlParams.get('theme') || 'light';
const userToken = urlParams.get('user_token') || process.env.REACT_APP_USER_TOKEN;
const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN;

var _ = require('lodash');

const i18nInstance = new Streami18n({
  language: 'ja'
});

const ax = axios.create({
  baseURL: 'https://ym0wuu9vcf.execute-api.ap-northeast-1.amazonaws.com/default/',
  headers: {
    "x-api-key": "4sKR5NHD2k8k4XqEK8qJB5lMZHTHd0Fy7HPcVbzh",
    'Content-Type': 'application/json'
  }
});

const client = StreamChat.getInstance<StreamChatType>(apiKey!, { enableInsights: true, enableWSFallback: true });
const geocore = require('@mekasmith/geocore');

geocore.SETTINGS = {
  PROJECT_ID: 'PRO-TNHP-1',
  BASE_URL: 'https://telenet-prod.geocore.jp/api'
};
geocore.setup(geocore.SETTINGS.BASE_URL, geocore.SETTINGS.PROJECT_ID);


const App = () => {
  useChecklist({ chatClient: client, targetOrigin: targetOrigin! });
  const [hasAuth, setHasAuth] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({id: ""});
  const [groups, setGroups] = useState([]);
  const [parentGroupName, setParentGroupName] = useState("");
  const [companyUserIds, setCompanyUserIds] = useState([]);

  const handleSubmit = async(e: any) => {
    e.preventDefault();
    setLoading(true);
    let streamRes;
    if(!username || !password) {
      toast.error('ユーザー名とパスワードは必須項目です。入力してください。', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      return;
    }
    try {
    streamRes = await ax.post('/telenetChatLoginWeb', {
      u: username,
      p: password
    }
    );
    } catch(e) {
      console.log("streamRes failed", e);
      toast.error('ログインできませんでした。ユーザー名またはパスワードが正しくありません。', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      setLoading(false);
      return;
    }
    let connectedUser;
    try {
      let streamIdPrefix = streamRes.data.user_type === "admin"? "USE-TNHP-1-A-": "USE-TNHP-1-T-";
      connectedUser = await client.connectUser({id: streamIdPrefix + username}, streamRes.data.token);
    } catch(e) {
      setLoading(false);
      toast.error('HazardChatにログインできませんでした。ネットワーク接続状況を確認してください。', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      console.log("connectedUser Failed:", e);
      return;
    }

    try {
      let streamIdPrefix = streamRes.data.user_type === "admin"? "USE-TNHP-1-A-": "USE-TNHP-1-T-";
      await geocore.login(streamIdPrefix + username, password);
      let userDataFetch = await geocore.users.get(streamIdPrefix + username);
      let userGroups = await geocore.users.groups(streamIdPrefix + username);
      let userCompanyGroup = _.find(userGroups, (obj: any) => {
        return obj.pk.group.id.startsWith("GRO-TNHP-1-C-");
      });

      let allCompanyUserIds = [];
      //get user ids in company
      const groupUsers = await geocore.get('/groups/' + userCompanyGroup?.pk?.group?.id + '/users');
      if(groupUsers && groupUsers.length > 0) {
        allCompanyUserIds = groupUsers.map((user: any) => user.pk.user.id);
        setCompanyUserIds(allCompanyUserIds);
      }

      setGroups(userGroups);
      if(userCompanyGroup?.pk?.group?.name) {
        setParentGroupName(userCompanyGroup?.pk?.group?.name)
      }

      setUserData(userDataFetch);
      setHasAuth(true);
    } catch(e) {
      //geocore failed to return
      setLoading(false);
      toast.error('HazardChatにログインできませんでした。ネットワーク接続状況を確認してください。', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      console.log("userData Failed:", e);
    }

  }

  const handleChange = (e: any) => {
    if(e.target.id === "username") {
      setUserName(e.target.value);
    }
    if(e.target.id === "password") {
      setPassword(e.target.value);
    }
  };

  useEffect(() => {
    const handleColorChange = (color: string) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty('--primary-color', `${color}E6`);
        root.style.setProperty('--primary-color-alpha', `${color}1A`);
      }
    };

    window.addEventListener('message', (event) => handleColorChange(event.data));
    return () => {
      client.disconnectUser();
      window.removeEventListener('message', (event) => handleColorChange(event.data));
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ minWidth: "420px" }}
        pauseOnHover
        theme="light"
        />
      <div className='app__wrapper str-chat'>
        {hasAuth?
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
        <Chat {...{ client, i18nInstance }} theme={`team ${theme}`}>

          <WorkspaceController>
            <Sidebar userData={userData} groups={groups} parentGroupName={parentGroupName} />
            <ChannelContainer companyUserIds={companyUserIds} geocore={geocore} />
          </WorkspaceController>

        </Chat></LocalizationProvider>:
        <div className="login-screen-wrapper">
          <div className="login-page-container">
              <div className="login-signup-form">
                <form onSubmit={handleSubmit}>
                  <FormControl margin="normal"  required fullWidth>
                    <InputLabel htmlFor="username" className="dark-theme-label">ユーザ名</InputLabel>
                    <Input id="username" name="username" className="dark-theme-input" disabled={loading} autoComplete="username" onChange={handleChange} autoFocus />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel className="dark-theme-label"  htmlFor="password">パスワード</InputLabel>
                    <Input
                      name="password"
                      type="password"
                      className="dark-theme-input"
                      id="password"
                      disabled={loading}
                      autoComplete="current-password"
                      onChange={handleChange}
                    />
                  </FormControl>
                    <div className="login-btn-wrap">

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          size="large"
                          className="btn-primary login-btn"
                          disabled={loading}
                          style={{marginTop: "5px"}}
                        >
                          {loading?
                            <CircularProgress size={20} thickness={6} className='loader-login-spinner' /> : <div><LockOutlinedIcon className='icon-lock' /><span>ログイン</span></div>}
                        </Button>
                    </div>
                </form>
              </div>
            </div>
        </div>}
      </div>
    </>
  );
};

export default App;
