import { MouseEventHandler, useCallback } from 'react';
import { Avatar, useChannelActionContext, useChannelStateContext, useChatContext } from 'stream-chat-react';

import { PinIcon } from '../../assets';

import { ChannelInfoIcon } from './ChannelInfoIcon';
import { useWorkspaceController } from '../../context/WorkspaceController';

import type { StreamChatType } from '../../types';

export const TeamChannelHeader = () => {
  const { displayWorkspace } = useWorkspaceController();
  const { client } = useChatContext<StreamChatType>();
  const { channel, watcher_count } = useChannelStateContext<StreamChatType>();
  const { closeThread } = useChannelActionContext<StreamChatType>();
  const { togglePinnedMessageListOpen } = useWorkspaceController();


  const channelMembers = Object.values(channel.state.members);
  const channelMembersFiltered = channelMembers.filter((u) => u.user_id !== client?.user?.id);
  const memberNames = channelMembersFiltered.map(obj => obj?.user?.name || obj?.user_id);


  const teamHeader = `# ${channel?.data?.name || memberNames || channel?.data?.id}`;

  const openChannelEditPanel = useCallback(() => {
    displayWorkspace('Admin-Channel-Edit');
  }, [displayWorkspace]);

  const onPinIconClick: MouseEventHandler = useCallback((event) => {
    closeThread?.(event);
    togglePinnedMessageListOpen();
  }, [closeThread, togglePinnedMessageListOpen])

  const getWatcherText = (watchers?: number) => {
    if (!watchers) return 'オンラインにユーザーがいない';
    if (watchers === 1) return '1 オンライン';
    return `${watchers} オンライン`;
  };

  return (
    <div className='team-channel-header__container'>

      <div className='workspace-header__block'>
        <div className='team-channel-header__name workspace-header__title'>{teamHeader}</div>
        <button onClick={openChannelEditPanel}>
          <ChannelInfoIcon />
        </button>
      </div>

      <div className='workspace-header__block'>
        <div className='workspace-header__subtitle'>{getWatcherText(watcher_count)}</div>
        <button
          className='workspace-header__subtitle'
          onClick={onPinIconClick}
        >
          <PinIcon />
          ピン
        </button>
      </div>
    </div>
  );
};
