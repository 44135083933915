import React, { useCallback, useContext, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

const noop = () => Promise.resolve();

export type Workspace = 'Chat' | 'Admin-Channel-Edit' | 'Admin-Channel-Create__team' | 'Admin-Channel-Create__messaging' | "Map";

type WorkspaceContext = {
  activeWorkspace: Workspace;
  closeAdminPanel: () => void;
  displayWorkspace: (w: Workspace) => void;
  pinnedMessageListOpen: boolean;
  togglePinnedMessageListOpen: () => void;
  closePinnedMessageListOpen: () => void;
  dateSelectedFilter: Dayjs | null;
  changeDate: (d: Dayjs | null) => void;
  updateRealtimeData: () => void;
  realtimeRefreshInt: number
}

const WorkspaceControllerContext = React.createContext<WorkspaceContext>({
  activeWorkspace: 'Chat',
  closeAdminPanel: noop,
  displayWorkspace: noop,
  pinnedMessageListOpen: false,
  togglePinnedMessageListOpen: noop,
  closePinnedMessageListOpen: noop,
  dateSelectedFilter: dayjs(),
  changeDate: noop,
  updateRealtimeData: noop,
  realtimeRefreshInt: 1
});


export const WorkspaceController = ({ children }: { children: React.ReactNode }) => {
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace>('Chat');
  const [pinnedMessageListOpen, setPinnedMessageListOpen] = useState(false);
  const [dateSelectedFilter, setDateSelectedFilter] = useState(dayjs());
  const [realtimeRefreshInt, setRealtimeRefreshInt] = useState(1);

  const displayWorkspace: WorkspaceContext['displayWorkspace'] = useCallback((workspace) => {
    setActiveWorkspace(workspace);
    setPinnedMessageListOpen(false);
  }, [setActiveWorkspace]);

  const closeAdminPanel = useCallback(() => {
    displayWorkspace('Chat');
  }, [displayWorkspace]);

  const changeDate = (date: Dayjs | null) => {
    if(date) {
      setDateSelectedFilter(date);
    }
  };

  const updateRealtimeData = () => {
    setRealtimeRefreshInt((prevState) => ++prevState);
  }

  const togglePinnedMessageListOpen = useCallback(() => setPinnedMessageListOpen((prev) => !prev), []);
  const closePinnedMessageListOpen = useCallback(() => setPinnedMessageListOpen(false), []);

  return (
    <WorkspaceControllerContext.Provider value={{
      activeWorkspace,
      closeAdminPanel,
      displayWorkspace,
      dateSelectedFilter,
      changeDate,
      pinnedMessageListOpen,
      closePinnedMessageListOpen,
      togglePinnedMessageListOpen,
      updateRealtimeData,
      realtimeRefreshInt,
    }}>
      {children}
    </WorkspaceControllerContext.Provider>
  );
};

export const useWorkspaceController = () => useContext(WorkspaceControllerContext);