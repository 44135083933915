import { useEffect, useMemo, useState } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';

import type { UserResponse } from 'stream-chat';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import type { StreamChatType } from '../../types';
import { useAdminPanelFormState } from './context/AdminPanelFormContext';
import { ValidationError } from './ValidationError';
import { format, render, cancel, register } from 'timeago.js';
import IconButton from '@mui/material/IconButton';

const ListContainer = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const {errors, createChannelType} = useAdminPanelFormState()
  const showHeading = !createChannelType || createChannelType === 'team';

  return (
    <div className='user-list__container'>
      {showHeading && <h2><span>メンバー追加</span><ValidationError errorMessage={errors.members} /></h2>}
      <div className='user-list__header user-list__row'>
        <div className='user-list__column-block'>
          <p>ユーザー</p>
          <p className='user-list__column--last-active'>最後のアクティブ</p>
        </div>
        <div className='user-list__column--checkbox'>
          <p>招待する</p>
        </div>
      </div>
      {children}
    </div>
  );
};

type UserItemProps = {
  index: number;
  user: UserResponse<StreamChatType>;
  currentMember: boolean;
  emptyList: boolean;
  directMessage: boolean;
};


const localeFunc = (number: number, index: number): [string, string]  => {
  return [
    ['すこし前', 'すぐに'],
    ['%s秒前', '%s秒以内'],
    ['1分前', '1分以内'],
    ['%s分前', '%s分以内'],
    ['1時間前', '1時間以内'],
    ['%s時間前', '%s時間以内'],
    ['1日前', '1日以内'],
    ['%s日前', '%s日以内'],
    ['1週間前', '1週間以内'],
    ['%s週間前', '%s週間以内'],
    ['1ヶ月前', '1ヶ月以内'],
    ['%sヶ月前', '%sヶ月以内'],
    ['1年前', '1年以内'],
    ['%s年前', '%s年以内'],
  ][index] as [string, string];
}

register('custom-locale', localeFunc);


type UserListLoadState = 'loading' | 'error' | 'empty';

const LOAD_STATE_NOTIFICATION: Record<UserListLoadState, string> = {
  empty: 'ユーザーが見つかりませんでした。',
  error: '読み込みエラーが発生しました。更新して再度お試しください。',
  loading: 'ユーザー読み込み中',
};

const UserItem = ({ index, user, currentMember, emptyList, directMessage }: UserItemProps) => {
  const { handleMemberSelect, members } = useAdminPanelFormState();
  const title = user.name || user.id;

  return (
    <label htmlFor={user.id} title={title} className='user-list__row'>
      <div className='user-list__column-block'>
        <div className='user-list__column--user-data'>
          <Avatar image={user.image} name={title} size={32} />
          <p className='user-item__name'>{title}</p>
        </div>
        {user && user.last_active ?
          <p className='user-list__column--last-active'>{format(user.last_active, "custom-locale")}</p>
          : null
        }
      </div>
      <div className='user-list__column--checkbox'>
        {directMessage ? (
          <input 
            type='checkbox' 
            name='members' 
            id={user.id} 
            value={user.id} 
            checked={members && members.includes(user.id) ? true : false} 
            onChange={(e) => handleMemberSelect(e, directMessage)} 
          />
        ) : (
          <input 
            type='checkbox' 
            name='members' 
            id={user.id} 
            value={user.id} 
            defaultChecked={emptyList ? false : currentMember} 
            onChange={(e) => handleMemberSelect(e, directMessage)} 
          />
        )}
      </div>
    </label>
  );
};



export const UserList = ({emptyList, directMessage}: {emptyList: boolean, directMessage: boolean}) => {
  const { client, channel } = useChatContext<StreamChatType>();
  const { createChannelType, members } = useAdminPanelFormState();
  const [loadState, setLoadState] = useState<UserListLoadState | null>(null);
  const [users, setUsers] = useState<UserResponse<StreamChatType>[] | undefined>();

  const channelMembers = useMemo(() => channel?.state.members
      ? Object.keys(channel.state.members)
      : [],
    [channel?.state?.members],
  );

  useEffect(() => {
    const getUsers = async () => {
      if (loadState) return;
      setLoadState('loading');

      try {

        /*
        const response = await client.queryUsers(
          { id: { $nin: channelMembers } }
        );
        */ 

        //Users that cannot be displayed to the current user due to lack of permissions will be omitted from response.
        //On create new channel, get all users except yourself
        const response = await client.queryUsers({
          id: {
            $nin: [client?.user?.id? client?.user?.id: ""],
          },
          role: 'user',
        });


        if (response.users.length) {
          setUsers(response.users);
        } else {
          setLoadState('empty');
        }
      } catch (event) {
        setLoadState('error');
      }

      setLoadState(null);
    };

    if (client) getUsers();
  }, [client, channelMembers, createChannelType]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortedUsers = users?.slice().sort((a, b) => {
    const aCurrentMember = channelMembers.includes(a.id);
    const bCurrentMember = channelMembers.includes(b.id);

    if (aCurrentMember && !bCurrentMember) {
      return -1;
    }
    if (!aCurrentMember && bCurrentMember) {
      return 1;
    }
    return 0;
  });
 
  return (

    <ListContainer>
      {loadState
        ? <div className='user-list__message'>{LOAD_STATE_NOTIFICATION[loadState]}</div>
        : (
          <>
            {createChannelType !== "team"
              ? sortedUsers?.length && sortedUsers.map((user, i) => <UserItem emptyList={emptyList} directMessage={directMessage} index={i} key={user.id} user={user} currentMember={channelMembers && channelMembers.includes(user.id) ? true : false} />)
              : users?.length && users.map((user, i) => <UserItem emptyList={emptyList} directMessage={directMessage} index={i} key={user.id} currentMember={channelMembers && channelMembers.includes(user.id) ? true : false} user={user} />)
            }
          </>
        )
      }
    </ListContainer>
  );
};
