import clsx from 'clsx';
import { useCallback } from 'react';
import { ChannelPreviewUIComponentProps, useChatContext } from 'stream-chat-react';

import { DirectMessagingChannelPreview } from './DirectMessagingChannelPreview';
import { TeamChannelPreview } from './TeamChannelPreview';

import { useWorkspaceController } from '../../context/WorkspaceController';

import type { StreamChatType } from '../../types';

type TeamChannelPreviewProps = ChannelPreviewUIComponentProps<StreamChatType> & {
  type: string;
};

export const ChannelPreview = ({ channel, type }: TeamChannelPreviewProps) => {
  const { channel: activeChannel, client, setActiveChannel } = useChatContext<StreamChatType>();
  const { displayWorkspace } = useWorkspaceController();
  const unreadCount = channel.state.unreadCount;

  const handleClick = useCallback(() => {
    displayWorkspace('Chat');
    if (setActiveChannel) {
      setActiveChannel(channel);
    }
  }, [channel, displayWorkspace, setActiveChannel]);

  const channelMembers = Object.values(channel.state.members);
  const channelMembersFiltered = channelMembers.filter((u) => u.user_id !== client?.user?.id);
  const memberNames = channelMembersFiltered.map(obj => obj?.user?.name || obj?.user_id);

  return (
    <button
      className={clsx('channel-preview', {selected: channel?.id === activeChannel?.id})}
      onClick={handleClick}
    >
      {type === 'team'
        ? <TeamChannelPreview name={channel?.data?.name || memberNames.join(", ") || channel?.data?.id as string} unreadCount={unreadCount}/>
        : <DirectMessagingChannelPreview channel={channel} unreadCount={unreadCount}/>
      }
    </button>
  );
};
